import { useAuth } from 'oidc-react';
import Button from '@mui/material/Button';
import { Groups, Campaign, Settings } from '@mui/icons-material';
import dayjs from 'dayjs';
import {
  Card,
  Alert,
  LinearProgress,
  TableRow,
  Chip,
  TableBody,
  TableCell,
  Grid,
  Table,
  Typography,
  Box,
  FormControl,
  Modal,
  Select,
  MenuItem,
} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ReactMarkdown from 'react-markdown';
import { People } from '@mui/icons-material';
import React from 'react';
import { timeAgo, daysUntilDate } from '../shared/data-utils';
import { Link, useParams } from 'react-router-dom';
import useFinding from '../hooks/useFinding';
import {
  getSeverityColor,
  replaceDescription,
  getStatusColor,
} from '../shared/campaigns';
import axios from 'axios';
import Config from '../shared/Config';
import { handlerApiError } from '../shared/errorHandler';
import { isCampaignsAdmin } from '../shared/serviceHandler';

const { apiBaseUrl } = Config();
const endpointUrl = `${apiBaseUrl}/findings`;
const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};
const config = { url: endpointUrl, headers };

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function max_date_by_severity(severity) {
  console.log(`Max date by severity ${severity}`);
  if (severity === 1) {
    return dayjs().add(1, 'week');
  }
  if (severity === 2) {
    return dayjs().add(2, 'weeks');
  }
  if (severity === 3) {
    return dayjs().add(3, 'weeks');
  }
  if (severity === 4) {
    return dayjs().add(2, 'month');
  }
  if (severity === 5) {
    return dayjs().add(4, 'months');
  }
}

const MarkdownRenderer = (markdown, data) => {
  let renderedMarkdown = markdown;

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const variable = `{${key}}`;
      const value = data[key];
      const regex = new RegExp(variable, 'g');
      renderedMarkdown = renderedMarkdown.replace(regex, value);
    }
  }

  return (
    <Typography component="div">
      <ReactMarkdown style={{ whiteSpace: 'pre-wrap' }}>
        {renderedMarkdown}
      </ReactMarkdown>
    </Typography>
  );
};

export default function FindingPage(params) {
  const auth = useAuth();
  const { id } = useParams();
  const { finding, loading } = useFinding(id);
  const [findingStatus, setStatus] = React.useState(finding.status);
  const [open, setOpen] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(null);

  const today = dayjs(); // Get the current date
  const tomorrow = today.add(1, 'day'); // Add 1 day to the current date
  const nextweek = dayjs().add(1, 'week');
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleAcknowledge = () => {
    const access_token = window.sessionStorage.getItem('token');
    headers.Authorization = `Bearer ${access_token}`;
    config.url = `${endpointUrl}/${id}/status`;
    if (selectedDate) {
      setStatus('acknowledged');
      const data = {
        status: 'acknowledged',
        ack_date: selectedDate.toISOString(),
      };

      axios
        .put(config.url, data, config)
        .then((response) => {
          console.log(response.data); // Handle the response as needed
          setOpen(false);
        })
        .catch((error) => {
          console.error(error); // Handle errors
        });
    }
  };

  const setStatusFinding = (config, id, status) => {
    const access_token = window.sessionStorage.getItem('token');
    headers.Authorization = `Bearer ${access_token}`;
    config.url = `${endpointUrl}/${id}/status`;
    axios
      .put(config.url, { status }, config)
      .then((response) => {
        const { data } = response;
        console.log(data);
        console.log('Successfully set finding status');
      })
      .catch((err) => handlerApiError(err));
  };

  const handleStatusChange = (event) => {
    console.log(`Changing status to ${event.target.value} for id: ${id}`);
    setStatus(event.target.value);
    setStatusFinding(config, id, event.target.value);
  };

  function getColor(severity) {
    switch (severity) {
      case 1:
        return 'error';
      case 2:
        return 'error';
      case 3:
        return 'warning';
      case 4:
        return 'info';
      case 5:
        return 'info';
      default:
        return 'info'; // fallback color
    }
  }
  return (
    <span>
      {loading ? (
        <span>
          <LinearProgress />
          loading finding {id}...
        </span>
      ) : (
        <span>
          <Card>
            <Alert
              variant="filled"
              style={{
                fontSize: '18px',
                backgroundColor: getSeverityColor(finding.severity),
              }}
              severity="error"
            >
              {replaceDescription(
                finding.description,
                finding.additional_fields
              )}
            </Alert>
            <Table size="small">
              <TableBody>
                {isCampaignsAdmin(auth) && (
                  <TableRow>
                    <TableCell>
                      {isCampaignsAdmin(auth) ? (
                        <b style={{ color: 'red' }}>Status</b>
                      ) : (
                        <b>Status</b>
                      )}
                    </TableCell>
                    <TableCell>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>
                          {isCampaignsAdmin(auth) ? (
                            <FormControl sx={{ m: 0 }} size="small">
                              <Select
                                id="status-small"
                                size="small"
                                value={findingStatus || finding.status}
                                onChange={handleStatusChange}
                              >
                                <MenuItem value={'open'}>open</MenuItem>
                                <MenuItem value={'acknowledged'}>
                                  acknowledged
                                </MenuItem>
                                <MenuItem value={'autoresolved'}>
                                  autoresolved
                                </MenuItem>
                                <MenuItem value={'fixed'}>fixed</MenuItem>
                                {isCampaignsAdmin(auth) && (
                                  <MenuItem
                                    sx={{ color: 'red' }}
                                    value={'ignored'}
                                  >
                                    ignored
                                  </MenuItem>
                                )}
                              </Select>
                            </FormControl>
                          ) : (
                            <Chip
                              size="small"
                              label={finding.status}
                              style={{
                                backgroundColor: getStatusColor(finding.status),
                              }}
                            />
                          )}
                        </Grid>
                        {(findingStatus == 'open' ||
                          finding.status == 'open') && (
                          <Grid item>
                            <div>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleOpen}
                              >
                                Acknowledge
                              </Button>
                              <Modal
                                open={open}
                                onClose={handleClose}
                                slots={{ backdrop: Backdrop }}
                                slotProps={{
                                  backdrop: {
                                    timeout: 500,
                                  },
                                }}
                              >
                                <Box sx={modalStyle}>
                                  <Typography
                                    id="transition-modal-title"
                                    variant="h6"
                                    component="h2"
                                    style={{ marginBottom: '30px' }}
                                  >
                                    Select an Acknowledgement Date
                                  </Typography>
                                  <Typography
                                    id="transition-modal-title"
                                    variant="p"
                                    component="p"
                                    style={{ marginBottom: '30px' }}
                                  >
                                    Findings after the acknowledged date will
                                    either move to fixed status, or escalated if
                                    not fixed
                                  </Typography>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DatePicker
                                      size="small"
                                      label="Select a date"
                                      views={['year', 'month', 'day']}
                                      defaultValue={nextweek}
                                      minDate={tomorrow}
                                      maxDate={max_date_by_severity(
                                        finding.severity
                                      )}
                                      onChange={handleDateChange}
                                    />
                                  </LocalizationProvider>
                                  <Button
                                    style={{ height: '100%' }}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleAcknowledge}
                                  >
                                    Ack
                                  </Button>
                                </Box>
                              </Modal>
                            </div>
                          </Grid>
                        )}
                        {finding.acknowledged_date && (
                          <span style={{ marginLeft: '10px' }}>
                            to be fixed by:{' '}
                            <b>
                              {
                                new Date(finding.acknowledged_date)
                                  .toLocaleString()
                                  .split(',')[0]
                              }
                            </b>{' '}
                            (
                            {daysUntilDate(
                              new Date(
                                finding.acknowledged_date
                              ).toLocaleString()
                            )}
                            {' days from today'})
                          </span>
                        )}
                      </Grid>
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell>
                    <b>Due Date</b>
                  </TableCell>
                  <TableCell>
                    <Chip size="small" label={finding.due_date} />
                    {finding.due_date != 'ongoing' &&
                      ` (in ${daysUntilDate(finding.due_date)} days)`}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Severity</b>
                  </TableCell>
                  <TableCell>
                    <Chip size="small" label={finding.severity} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Name</b>
                  </TableCell>
                  <TableCell>{finding.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Campaign</b>
                  </TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      startIcon={<Campaign />}
                      component={Link}
                      to={`/campaigns/${finding.campaign}`}
                    >
                      {finding.campaign}
                    </Button>
                  </TableCell>
                </TableRow>
                {finding.team != 'unknown' && (
                  <TableRow>
                    <TableCell>
                      <b>Team</b>
                    </TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        startIcon={<Groups />}
                        component={Link}
                        to={`/teams/${finding.team}`}
                      >
                        {finding.team}
                      </Button>
                    </TableCell>
                  </TableRow>
                )}
                {finding.service != 'unknown' && (
                  <TableRow>
                    <TableCell>
                      <b>Service</b>
                    </TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        target="_blank"
                        startIcon={<Settings />}
                        href={`https://puffdir.sre.gopuff.com/service/${finding.service}`}
                      >
                        {finding.service}
                      </Button>
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell>
                    <b>Created At</b>
                  </TableCell>
                  <TableCell>
                    {finding.created_at &&
                      new Date(finding.created_at).toLocaleString()}{' '}
                    ({timeAgo(finding.created_at)} ago)
                  </TableCell>
                </TableRow>
                {Object.keys(finding.additional_fields).map((key) => (
                  <TableRow key={key}>
                    <TableCell>
                      <b>
                        {key.charAt(0).toUpperCase() +
                          key.slice(1).replace(/_/g, ' ')}
                      </b>
                    </TableCell>
                    <TableCell>
                      {finding.additional_fields[key] &&
                      String(finding.additional_fields[key]).includes(
                        '@gopuff.com'
                      ) ? (
                        <Button
                          startIcon={<People />}
                          size="small"
                          target="_blank"
                          href={`https://puffdir.sre.gopuff.com/person/${finding.additional_fields[
                            key
                          ].replace('@gopuff.com', '')}`}
                        >
                          {finding.additional_fields[key].replace(
                            '@gopuff.com',
                            ''
                          )}
                        </Button>
                      ) : (
                        finding.additional_fields[key]
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell>
                    <b>Runbook</b>
                  </TableCell>
                  <TableCell>
                    {finding.runbook
                      ? MarkdownRenderer(
                          finding.runbook,
                          finding.additional_fields
                        )
                      : 'No runbook defined for this issue, if you want to know more, contact the campaign creator'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Card>
        </span>
      )}
    </span>
  );
}
